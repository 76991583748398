<template>
  <div>
    <!-- page title ################### -->
    <div class="big-head">
      <v-layout align-center>
        <span>#{{ $route.params.order }}</span>
        <v-flex grow>
          <h1>
            {{ $route.params.name }}
            <em></em>
          </h1>
        </v-flex>
        <v-flex blue-select shrink>
          <!-- meio de pagamento ########### -->
          <!-- <InputSelect
            ref="companyGroupListInput"
            textLabel
            v-model="paymentMethod"
            :items="[{id: 1, name: 'Débito em Folha'}]"
            valueAttribute="id"
            textAttribute="name"
            placeholder="Meio de Pagto"
          ></InputSelect>-->
        </v-flex>
        <v-flex shrink>
          <InputSelect
            class="ml-3"
            :items="[
              { id: 10, name: 10 },
              { id: 20, name: 20 },
              { id: 50, name: 50 },
              { id: 100, name: 100 },
            ]"
            valueAttribute="id"
            textAttribute="name"
            v-model="pageSize"
          />
        </v-flex>
      </v-layout>
    </div>
    <ListDonationsCashFlow
      :enableCheckbox="false"
      :enableDownloadExcelCsv="true"
      :enableModalLevel2="true"
      :enableOpenModalUser="false"
      :level="2"
      :paymentMethod="paymentMethod"
      :pageSize="pageSize"
      :statusConciliation="statusConciliation"
      :pageNumber="cashFlowPagesEnum.ConciliationByCompany"
      :showTaxProjectIncentived="false"
      :showTaxProjectNotIncentived="false"
    ></ListDonationsCashFlow>
  </div>
</template>
<script type="plain/text">
import ListDonationsCashFlow from "@/views/app/resourceFlow/ListDonationsCashFlow.vue";
import PaymentMethod from "@/scripts/models/enums/paymentMethod.enum";
import { CashFlowPages } from "@/scripts/models/enums/cashFlowPages.enum";
import DonationConciliationStatus from "@/scripts/models/enums/donationConciliationStatus.enum";

export default {
  components: {
    ListDonationsCashFlow,
  },
  props: {
    pageOptions: null,
  },
  data() {
    return {
      paymentMethod: PaymentMethod.PayrollDeduction,
      cashFlowPagesEnum: "",
      pageSize: 10,
      statusConciliation:
        DonationConciliationStatus.PayRollOnConciliationByCompany,
    };
  },
  created() {
    this.cashFlowPagesEnum = CashFlowPages;
    this.$emit("pageActive", CashFlowPages.ConciliationByCompany);
  },
};
</script>
